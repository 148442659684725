import FormWrapper from "components/FormWrapper";
import { ClaimForm } from "./ClaimForm";

const SubmitClaimScreen = () => {
  return (
    <FormWrapper>
      <ClaimForm />
    </FormWrapper>
  );
};

export default SubmitClaimScreen;
