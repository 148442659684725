import FormWrapper from "components/FormWrapper";
import { UMRForm } from "./UMRForm";

const UmrScreen = () => {
  return (
    <FormWrapper>
      <UMRForm />
    </FormWrapper>
  );
};

export default UmrScreen;
