import { createTheme, responsiveFontSizes } from "@mui/material/styles";

export enum ThemeColors {
  BLUE = "#4FB6D7",
  LIGHT_GRAY = "#F5F5F7",
  ERROR = "#FD4444",
  BODY_TEXT = "#666666",
}

const theme = createTheme({
  palette: {
    primary: {
      main: ThemeColors.BLUE,
    },
    error: { main: ThemeColors.ERROR },
  },
  components: {
    MuiButton: { defaultProps: { disableRipple: false } },
    MuiCssBaseline: {
      styleOverrides: {
        body: { letterSpacing: "1.1px" },
      },
    },
  },

  typography: {
    fontFamily: "Avenir",
  },
});

export default responsiveFontSizes(theme);
