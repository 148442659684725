const SvgSelectUp = () => {
  return (
    <svg
      width="16"
      height="11"
      viewBox="0 0 16 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 9.54752L7.84831 2.69922L14.6966 9.54752"
        stroke="#00B8DB"
        strokeWidth="2.44928"
      />
    </svg>
  );
};

export default SvgSelectUp;
