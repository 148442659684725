import styled from "styled-components";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useStateSelector } from "state";

const Div = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: -1;
  top: 0;
  left: 0;

  /* background: conic-gradient(
    from 21rad at 50% 65%,
    #4691b8,
    #2074aa,
    #1d5f9e,
    #29438c,
    #372077,
    #1b68a4,
    #0999c1,
    #4691b8
  ); */
`;

const MotionDiv = motion(Div);

const Background = () => {
  const idx = useStateSelector(({ app }) => app.idx);

  const [height, setHeight] = useState(65);
  const [color1, setColor1] = useState("#1d5f9e");

  useEffect(() => {
    if (idx === 1) {
      setHeight(65);
      setColor1("#1d5f9e");
    } else {
      setHeight(3);
      setColor1("#372077");
    }
  }, [idx, setHeight]);

  return (
    <MotionDiv
      animate={{
        background: `conic-gradient(
          from 0rad at 50% ${height}%,
          #4FB6D7 ,
          #2074aa,
          #372077 90deg,
          #372077,
          #372077,
           ${color1},
          #4FB6D7 300deg,
          #4FB6D7 
        )`,
      }}
      transition={{
        type: "spring",
        damping: 35,
        stiffness: 350,
        restDelta: 0.01,
      }}
    />
  );
};

export default Background;
