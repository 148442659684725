import useBreakpoint from "hooks/useBreakpoint";
import styled from "styled-components";

const Img = styled.img`
  position: absolute;
  top: 54px;
  left: 54px;
`;

export const LogoWeb = () => {
  const isSm = useBreakpoint("sm");
  if (isSm) return null;
  return <Img src="images/brit-logo-white.svg" />;
};
