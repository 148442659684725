import { useState, useEffect, useRef } from "react";
import {
  Button,
  DropDownIndicator,
  EndText,
  HelperText,
  Input,
  StartText,
  StyledInputAdornment,
  StyledInputAdornmentMulti,
  StyledSelect,
  TextArea,
} from "components/form";
import Spacer from "components/Spacer";
import styled from "styled-components";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useStateSelector } from "state";
import { IAdjuster } from "api/types";
import { DatePicker } from "@mui/x-date-pickers";
import ImagePicker from "components/ImagePicker/ImagePicker";
import useActionsApp from "state/actionHooks/useActionsApp";
import isEqual from "lodash.isequal";
import SvgSelectUp from "assets/SvgSelectUp";
import SvgSelectDown from "assets/SvgSelectDown";

const Div = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  #calendar {
    margin-bottom: 5px;
  }

  .form-control {
    .Mui-selected {
      display: none !important;
    }
  }

  .MuiPaper-root {
    background-color: #eaea87;
  }
`;

interface IOption {
  label: string;
  value: string;
}

const selectStyle = {
  menuList: () => ({
    padding: 0,
  }),
};

const validationSchema = Yup.object({
  adjuster: Yup.object({
    name: Yup.string().required("Required"),
    emailAddress: Yup.string(),
    telephone: Yup.string(),
  }),
  insuredName: Yup.string().required("Required"),
  contactDetails: Yup.string().required("Required"),
  dateOfLoss: Yup.string().notRequired(),
  locationOfLoss: Yup.string().required("Required"),
  lossDetails: Yup.string(),
});

export const ClaimForm = () => {
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [isSelectFocused, setIsSelectFocused] = useState(false);
  const { adjusters, next } = useStateSelector(({ app }) => {
    return { adjusters: app.adjusters, next: app.next };
  }, isEqual);
  const { setImages, setFormData, setCanProceed } = useActionsApp();

  const insuredNameRef = useRef(null);
  const contactDetailsRef = useRef(null);
  const locationOfLossRef = useRef(null);
  const pickerRef = useRef(null);

  const {
    handleChange,
    handleBlur,
    values,
    isValid,
    isSubmitting,
    setTouched,
    touched,
    errors,
    setErrors,
    setValues,
    resetForm,
  } = useFormik({
    initialValues: {
      adjuster: { name: "", emailAddress: "", telephone: "" },
      insuredName: adjusters?.insuredName,
      contactDetails: "",
      dateOfLoss: undefined,
      locationOfLoss: "",
      lossDetails: "",
    },
    validationSchema,
    isInitialValid: false,
    enableReinitialize: true,
    onSubmit: () => {},
  });

  useEffect(() => {
    setFormData(values);
  }, [values]);

  useEffect(() => {
    if (!isValid) {
      setCanProceed(false);
    } else {
      setCanProceed(true);
    }
  }, [isValid]);

  useEffect(() => {
    resetForm();
    setImages([]);
  }, [adjusters]);

  const handleOnSelect = (option: IOption) => {
    if (adjusters) {
      const adjuster = adjusters.adjusters.find(
        (el) => el.name === option.value
      );
      setValues({ ...values, adjuster: adjuster as IAdjuster });
    }
  };

  const proceed = () => {
    if (isValid) {
      next();
    }
  };

  if (!adjusters) return null;

  return (
    <Div>
      {/* ADJUSTERS */}
      {adjusters && (
        <>
          <StyledSelect
            tabIndex={-1}
            placeholder="Select Adjuster*"
            isSearchable={false}
            value={
              values.adjuster.name
                ? { label: values.adjuster.name, value: values.adjuster.name }
                : null
            }
            options={adjusters.adjusters.map((el) => {
              return { label: el.name, value: el.name };
            })}
            onMenuOpen={() => {
              setIsSelectFocused(true);
            }}
            onMenuClose={() => {
              // @ts-ignore
              setTouched({ ...touched, adjuster: true });
              setIsSelectFocused(false);
            }}
            // @ts-ignore
            onChange={(opt: IOption) => {
              handleOnSelect(opt);
              setIsSelectFocused(false);
              setErrors({ ...errors, adjuster: undefined });
            }}
            components={{
              DropdownIndicator: () => {
                return (
                  <DropDownIndicator>
                    {isSelectFocused ? <SvgSelectDown /> : <SvgSelectUp />}
                  </DropDownIndicator>
                );
              },
            }}
            error={Boolean(
              touched.adjuster && errors.adjuster && !values.adjuster.name
            )}
            styles={selectStyle}
          />
          <HelperText
            enabled
            text={
              Boolean(
                touched.adjuster && errors.adjuster && !values.adjuster.name
              )
                ? "Required"
                : ""
            }
            error
          />
          <Spacer xs={10} />
        </>
      )}
      {/* INSURED NAME */}
      <>
        <Input
          inputRef={insuredNameRef}
          placeholder="Insured Name*"
          InputProps={{
            tabIndex: -1,
            inputMode: "none",
            startAdornment: values.insuredName ? (
              <StyledInputAdornmentMulti
                position="start"
                onClick={() => {
                  // @ts-ignore
                  insuredNameRef.current?.focus();
                }}
              >
                <StartText>Insured Name:</StartText>
              </StyledInputAdornmentMulti>
            ) : null,
          }}
          id="insuredName"
          name="insuredName"
          type="text"
          error={Boolean(touched.insuredName && errors.insuredName)}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.insuredName}
          multiline
        />
        <HelperText
          enabled
          text={(touched.insuredName && errors.insuredName) as string}
          error
        />
        <Spacer xs={10} />
      </>
      {/* CONTACT DETAILS */}
      <>
        <Input
          inputRef={contactDetailsRef}
          placeholder="Contact Details*"
          InputProps={{
            tabIndex: -1,
            endAdornment: values.contactDetails ? null : (
              <StyledInputAdornment
                position="start"
                onClick={() => {
                  // @ts-ignore
                  contactDetailsRef.current?.focus();
                }}
              >
                <EndText>Include email for a copy</EndText>
              </StyledInputAdornment>
            ),
            startAdornment: values.contactDetails ? (
              <>
                <StyledInputAdornmentMulti
                  position="start"
                  onClick={() => {
                    // @ts-ignore
                    contactDetailsRef.current?.focus();
                  }}
                >
                  <StartText>Contact Details:</StartText>
                </StyledInputAdornmentMulti>
              </>
            ) : null,
          }}
          id="contactDetails"
          name="contactDetails"
          type="text"
          error={Boolean(touched.contactDetails && errors.contactDetails)}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.contactDetails}
          multiline
        />
        <HelperText
          enabled
          text={(touched.contactDetails && errors.contactDetails) as string}
          error
        />
        <Spacer xs={10} />
      </>
      {/* DATE OF LOSS */}
      <>
        <DatePicker
          ref={pickerRef}
          showToolbar={false}
          disableHighlightToday
          disableMaskedInput
          autoFocus={false}
          open={isDatePickerOpen}
          onOpen={() => setIsDatePickerOpen(true)}
          onClose={() => setIsDatePickerOpen(false)}
          value={values.dateOfLoss}
          inputFormat="DD/MM/YYYY"
          onChange={(date) => {
            if (date) {
              setIsDatePickerOpen(false);
              setValues({
                ...values,
                // @ts-ignore
                dateOfLoss: date.toString(),
              });
            }
          }}
          disableFuture
          OpenPickerButtonProps={{ color: "primary" }}
          PopperProps={{
            placement: "bottom-end",
          }}
          InputAdornmentProps={{ position: "end" }}
          componentsProps={{
            actionBar: {
              actions: [],
            },
          }}
          renderInput={(params) => {
            return (
              // @ts-ignore
              <Input
                sx={{ ".MuiInputBase-root": { paddingRight: "10px" } }}
                id="dateOfLoss"
                name="dateOfLoss"
                onBlur={handleBlur}
                onClick={() => setIsDatePickerOpen(true)}
                {...params}
                autoComplete="off"
                InputProps={{
                  tabIndex: -1,
                  startAdornment: values.dateOfLoss ? (
                    <>
                      <StyledInputAdornment
                        position="start"
                        sx={{ marginBottom: "2px" }}
                      >
                        <StartText>Date of Loss:</StartText>
                      </StyledInputAdornment>
                    </>
                  ) : null,
                  endAdornment: <img id="calendar" src="images/calendar.svg" />,
                }}
                inputProps={{
                  ...params.inputProps,
                  tabIndex: -1,
                  placeholder: "Date of Loss",
                  // @ts-ignore
                  value: values.dateOfLoss ? params.inputProps.value : "",
                }}
              />
            );
          }}
        />

        <Spacer xs={20} />
      </>
      {/* LOCATION OF LOSS */}
      <>
        <Input
          inputRef={locationOfLossRef}
          placeholder="Location of Loss*"
          InputProps={{
            tabIndex: -1,
            endAdornment: values.locationOfLoss ? null : (
              <StyledInputAdornment
                position="start"
                onClick={() => {
                  // @ts-ignore
                  locationOfLossRef.current?.focus();
                }}
              >
                <EndText>Enter the address</EndText>
              </StyledInputAdornment>
            ),
            startAdornment: values.locationOfLoss ? (
              <>
                <StyledInputAdornmentMulti
                  position="start"
                  onClick={() => {
                    // @ts-ignore
                    locationOfLossRef.current?.focus();
                  }}
                >
                  <StartText>Location of Loss:</StartText>
                </StyledInputAdornmentMulti>
              </>
            ) : null,
          }}
          id="locationOfLoss"
          name="locationOfLoss"
          type="text"
          error={Boolean(touched.locationOfLoss && errors.locationOfLoss)}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.locationOfLoss}
          multiline
        />
        <HelperText
          enabled
          text={(touched.locationOfLoss && errors.locationOfLoss) as string}
          error
        />
        <Spacer xs={18} />
      </>
      {/* LOSS DETAILS */}
      <>
        <TextArea
          placeholder="Loss Details"
          id="lossDetails"
          name="lossDetails"
          type="text"
          error={Boolean(touched.lossDetails && errors.lossDetails)}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.lossDetails}
        />
        <HelperText
          enabled
          text={(touched.lossDetails && errors.lossDetails) as string}
          error
        />
        <Spacer xs={17} />
      </>
      <Spacer xs={35} />
      <Button onClick={proceed} disabled={!isValid || isSubmitting}>
        PROCEED
      </Button>
    </Div>
  );
};
