import useBreakpoint from "hooks/useBreakpoint";
import { useStateSelector } from "state";
import styled from "styled-components";

const Div = styled.div`
  width: 100%;
  padding: 0 18px;

  #contents {
    transform: translateY(60px);
    /* border: 1px solid green; */
    display: flex;
    justify-content: space-between;
    align-items: center;

    .arrow-container {
      padding: 0 20px;
      position: absolute;
      /* border: 1px solid white; */
      height: 50px;
      cursor: pointer;
      display: flex;
      align-items: center;
    }

    .right {
      right: 0;
    }
    .justify-end {
      justify-content: flex-end;
    }
  }
`;

const ReturnDiv = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  flex: 0;
`;

interface INavProps {
  left?: boolean;
  right?: boolean;
}

export const NavSmall: React.FC<INavProps> = ({ left, right }) => {
  const { next, prev } = useStateSelector(({ app }) => {
    return { idx: app.idx, next: app.next, prev: app.prev };
  });

  const isSm = useBreakpoint("sm");

  if (!isSm) return null;

  return (
    <Div>
      <div id="contents">
        {left && (
          <div className="arrow-container" onClick={() => prev()}>
            <img src="images/chevron-left.svg" alt="" />
          </div>
        )}
        {right && (
          <div
            className="arrow-container right justify-end"
            onClick={() => next()}
          >
            <img src="images/chevron-right.svg" alt="" />
          </div>
        )}
      </div>
    </Div>
  );
};
