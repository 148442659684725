import axios, { AxiosResponse } from "axios";
import { AxiosArgs, IAdjuster, IAjusters, IImage, ThenArgs } from "./types";

export const client = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

client.interceptors.response.use(
  (response) => response,
  (err) => {
    return Promise.reject(err.response?.data);
  }
);

export const getHealthcheck = () => {
  const path = `/healthcheck`;
  return client({
    method: "GET",
    url: path,
  }).then((res) => res.data);
};

// ------------------------------------------------

type GetAdjustersResponse = Promise<
  AxiosArgs<ThenArgs<AxiosResponse<IAjusters>>>
>;
type GetAdjusters = (a: string) => GetAdjustersResponse;

export const getAdjusters: GetAdjusters = (umr) => {
  const path = `/adjusters/${umr}`;
  return client({
    method: "GET",
    url: path,
  }).then((res) => res.data);
};

// ------------------------------------------------

type SubmitClaim = (a: {
  adjuster: IAdjuster;
  contactDetails: string;
  dateOfLoss: string;
  insuredName: string;
  umr: string;
  locationOfLoss: string;
  lossDetails: string;
  meta: {
    insuredName: string;
    broker: { name: string; emailAddress: string };
  };
}) => Promise<AxiosArgs<ThenArgs<AxiosResponse<null>>>>;

export const submitClaim: SubmitClaim = (data) => {
  const path = `/submit`;
  return client({
    method: "POST",
    url: path,
    data,
  }).then((res) => res.data);
};
