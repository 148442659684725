import { getHealthcheck } from "api/client";
import { Avenir } from "components/Avenir";
import { LogoMobile } from "components/Logo";
import Spacer from "components/Spacer";
import useBreakpoint from "hooks/useBreakpoint";
import { useStateSelector } from "state";
import styled from "styled-components";
import { ThemeColors } from "util/theme";

const Div = styled.div`
  height: 100vh;
  /* border: 1px solid red; */
  * {
    user-select: none;
  }

  #brit {
    position: absolute;
    left: -30px;
    font-size: 400px;
    top: -30px;
    color: white;
    font-weight: bold !important;
    #opacity-75 {
      opacity: 0.75;
    }
    #opacity-45 {
      opacity: 0.45;
    }
    #opacity-20 {
      opacity: 0.2;
    }
  }

  #brit-logo-mobile {
    display: flex;
    width: 100%;
    justify-content: space-between;
    top: 60px;
    position: absolute;
    left: -30px;
    font-weight: 600;
    font-size: 320px;
    color: white;
    transform: translateX(-20px);

    #low-opacity {
      opacity: 0.12;
    }
  }

  #title {
    position: absolute;
    color: white;
    left: 50%;
    bottom: calc(35% + 120px);
    font-size: 30px;
    font-weight: 400;
    #br {
      display: none;
    }
  }

  #box {
    position: absolute;
    width: 50%;
    height: 100px;
    background-color: ${ThemeColors.BLUE};
    left: 50%;
    bottom: 35%;
  }

  #nav {
    position: absolute;
    bottom: 20px;
    width: 100%;
    height: 260px;
    /* border: 1px solid red; */
    display: flex;
    justify-content: center;

    #spacer {
      width: 290px;
    }

    .nav-item {
      /* border: 1px solid red; */
      letter-spacing: 2px;
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
    }
  }

  ${({ theme }) => theme.breakpoints.down("lg")} {
    #brit {
      font-size: 300px;
    }
    #title {
      bottom: calc(35% + 90px);
      font-size: 20px;
    }
    #box {
      height: 70px;
    }

    #nav {
      height: 200px;

      #spacer {
        width: 150px;
      }

      .nav-item {
        img {
          height: 60%;
        }
      }
    }
  }
  ${({ theme }) => theme.breakpoints.down("md")} {
    #brit {
      top: 0;
      font-size: 250px;
    }
    #title {
      bottom: calc(35% + 70px);
      margin-right: 10px;
      text-align: right;
    }
    #box {
      height: 50px;
    }

    #nav {
      height: 150px;

      #spacer {
        width: 80px;
      }

      .nav-item {
        img {
          height: 50%;
        }
      }
    }
  }
  ${({ theme }) => theme.breakpoints.down("sm")} {
    #brit {
      top: 0;
      font-size: 250px;
    }
    #brit-logo-mobile {
      letter-spacing: 35px;
      top: 10px;
    }
    #title {
      bottom: calc(35% + 70px);
      font-size: 20px;
      font-weight: 300;
      left: 30%;
      right: 0%;
      #br {
        display: inline;
      }
    }
    #box {
      height: 50px;
    }

    #nav {
      height: 140px;

      #spacer {
        width: 40px;
      }

      .nav-item {
        img {
          height: 60%;
        }
        .nav-item-text {
          font-size: 12px;
        }
      }
    }
  }
`;

const HomeScreen = () => {
  const { next, prev } = useStateSelector(({ app }) => {
    return { next: app.next, prev: app.prev };
  });

  const isSm = useBreakpoint("sm");

  const healthcheckAction = () => {
    getHealthcheck()
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log("err:", err);
      });
  };
  return (
    <Div>
      <LogoMobile isHome />
      {isSm ? (
        <div id="brit-logo-mobile">
          B<span id="low-opacity">R</span>
        </div>
      ) : (
        <span id="brit">
          B<span id="opacity-75">R</span>
          <span id="opacity-45">I</span>
          <span id="opacity-20">T</span>
        </span>
      )}

      <span id="title" onClick={healthcheckAction}>
        Welcome to the <br id="br" />
        Brit Energy Claims Portal
      </span>

      <div id="box" />
      <div id="nav">
        <div className="nav-item" onClick={() => prev()}>
          <img src="images/claims-checklist.svg" alt="" />
          <Spacer xs={17} />
          <Avenir className="nav-item-text" weight="500" color={"white"}>
            CLAIMS CHECKLIST
          </Avenir>
        </div>
        {/* <Spacer xs={290} horizontal /> */}
        <div id="spacer" />
        <div className="nav-item" onClick={() => next()}>
          <img src="images/submit-claim-home.svg" alt="" />
          <Spacer xs={17} />
          <Avenir className="nav-item-text" weight="500" color={"white"}>
            SUBMIT A CLAIM
          </Avenir>
        </div>
      </div>
      {/* <span>BRIT</span> */}
    </Div>
  );
};

export default HomeScreen;
