import { useEffect } from "react";
import styled from "styled-components";
import Background from "components/Background";
import HomeScreen from "screens/HomeScreen";
import ChecklistScreen from "screens/ChecklistScreen";
import "keen-slider/keen-slider.min.css";
import { useKeenSlider } from "keen-slider/react";
import { SlideContainer } from "components/Slider/comps";
import Nav from "components/Nav";
import SubmitClaimScreen from "screens/SubmitClaimScreen";
import useActionsApp from "state/actionHooks/useActionsApp";
import FinalScreen from "screens/FinalScreen";
import { LogoWeb } from "components/Logo";
import { useStateSelector } from "state";
import isEqual from "lodash.isequal";
import UmrScreen from "screens/UmrScreen";
import ConfirmScreen from "screens/ConfirmScreen";

const Div = styled.div`
  height: 100vh;
`;

const App = () => {
  const { setIdx, setNavigation } = useActionsApp();
  const {
    withSubmitStatus,
    tempEnableReturnToForm,
    withAdjusters,
    canProceed,
  } = useStateSelector(({ app }) => {
    return {
      withSubmitStatus: app.submitStatus !== undefined,
      tempEnableReturnToForm: app.tempEnableReturnToForm,
      withAdjusters: Boolean(app.adjusters),
      canProceed: app.canProceed,
    };
  }, isEqual);

  const [refCallback, slider] = useKeenSlider({
    slideChanged: (slider) => {
      setIdx(slider.track.details.abs);
    },
    range: withAdjusters
      ? !canProceed
        ? { min: 0, max: 3 }
        : withSubmitStatus
        ? { min: tempEnableReturnToForm ? 3 : 5, max: 5 }
        : { min: 0, max: 4 }
      : { min: 0, max: 2 },
    rubberband: false,
    drag: true,
    initial: 1,
  });

  useEffect(() => {
    if (slider.current) {
      setNavigation({
        prev: slider.current.prev,
        next: slider.current.next,
        moveToIdx: slider.current.moveToIdx,
      });
    }
  }, []);

  return (
    <>
      <Div ref={refCallback} className="keen-slider">
        <Nav />
        <LogoWeb />

        <SlideContainer>
          <ChecklistScreen />
        </SlideContainer>

        <SlideContainer>
          <HomeScreen />
        </SlideContainer>

        <SlideContainer>
          <UmrScreen />
        </SlideContainer>

        <SlideContainer>
          <SubmitClaimScreen />
        </SlideContainer>

        <SlideContainer>
          <ConfirmScreen />
        </SlideContainer>

        <SlideContainer>
          <FinalScreen />
        </SlideContainer>

        <Background />
      </Div>
    </>
  );
};

export default App;
