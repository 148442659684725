import { useState } from "react";
import FormWrapper from "components/FormWrapper";
import { useStateSelector } from "state";
import styled from "styled-components";
import { Avenir } from "components/Avenir";
import useBreakpoint from "hooks/useBreakpoint";
import { Button } from "components/form";
import { SubmitStatus } from "state/reducers/app";
import useActionsApp from "state/actionHooks/useActionsApp";
import { submitClaim } from "api/client";
import Spacer from "components/Spacer";
import ImagePicker from "components/ImagePicker";
import moment from "moment";

const Div = styled.div`
  margin-top: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const ItemDiv = styled.div`
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  margin-bottom: 20px;

  .title {
    font-weight: 500;
  }
  padding-bottom: 4px;
`;

const Item: React.FC<{ title: string; text?: string }> = ({ title, text }) => {
  const isSm = useBreakpoint("sm");

  return (
    <ItemDiv>
      <Avenir color={"white"} fontSize={isSm ? 14 : 16}>
        <span className="title">{title}:</span> {text}
      </Avenir>
    </ItemDiv>
  );
};

const ConfirmScreen = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { formData, adjusters, next } = useStateSelector(({ app }) => {
    return {
      formData: app.formData,
      adjusters: app.adjusters,
      next: app.next,
    };
  });

  const { setSubmitStatus } = useActionsApp();

  if (!formData) return null;

  const submit = () => {
    const data = {
      ...formData,
      dateOfLoss: moment(formData.dateOfLoss).toISOString(),
      umr: adjusters?.umr as string,
      meta: {
        insuredName: adjusters?.insuredName || "",
        broker: adjusters?.broker || { name: "", emailAddress: "" },
      },
    };

    setIsLoading(true);

    submitClaim(data)
      .then(() => setSubmitStatus(SubmitStatus.Success))
      .catch((err) => {
        setSubmitStatus(SubmitStatus.Error);
        console.log("err:", err);
      })
      .finally(() => {
        setIsLoading(false);
        setTimeout(() => next(), 10);
      });
  };

  return (
    <FormWrapper isConfirmScreen>
      <Div>
        <Item title="Unique market reference" text={adjusters?.umr} />
        <Item title="Adjuster" text={formData.adjuster?.name} />
        <Item title="Insured Name" text={formData.insuredName} />
        <Item title="Contact Details" text={formData.contactDetails} />
        <Item
          title="Date of Loss"
          text={moment(formData.dateOfLoss).format("DD/MM/YYYY")}
        />
        <Item title="Location of Loss" text={formData.locationOfLoss} />
        <Item title="Loss Details" text={formData.lossDetails} />
      </Div>
      <Spacer xs={35} />
      <Button fullWidth onClick={submit} disabled={isLoading}>
        SUBMIT
      </Button>
    </FormWrapper>
  );
};

export default ConfirmScreen;
