import { Avenir } from "components/Avenir";
import Dash from "components/Dash";
import { LogoMobile } from "components/Logo";
import { NavSmall } from "components/Nav";
import Spacer from "components/Spacer";
import useBreakpoint from "hooks/useBreakpoint";
import styled from "styled-components";

const Div = styled.div`
  height: 100vh;
  overflow-x: auto;

  ::-webkit-scrollbar {
    display: none;
  }

  #top {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* border: 1px solid red; */
  }

  #checklist-container {
    /* border: 1px solid white; */
    margin: 0 auto;
    padding: 0 35px;
    max-width: 1500px;
    display: flex;
    flex-direction: column;

    .checklist-item {
      margin-bottom: 10px;
      display: flex;
      align-items: flex-start;
    }
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    #icon {
      height: 20px;
    }
  }
`;

const list = [
  "DAMAGE REPORT",
  "INCIDENT REPORT",
  "POLICE REPORT",
  "INITIAL CAUSE REPORT",
  "REPLACEMENT QUOTE",
  "REPAIR QUOTE",
  "MAINTENANCE HISTORY",
  "RELEVANT PERSONNEL DETAILS",
  "WEATHER DATA",
  "RELEVANT AUTHORITY REPORTS / ORDERS",
  "RELEVANT CONTRACTS",
  "CORRESPONDENCE WITH POSSIBLE RESPONSIBLE PARTIES",
];

const ChecklistScreen = () => {
  const isSm = useBreakpoint("sm");

  return (
    <Div>
      <div id="top">
        <NavSmall right />
        <Spacer xs={50} />
        <LogoMobile />
        <Spacer xs={isSm ? 30 : 0} />
        <img id="icon" src="images/checklist.svg" alt="" />
        <Spacer xs={isSm ? 10 : 25} />
        <Avenir color={"white"} fontSize={isSm ? 28 : 34}>
          Claims Checklist
        </Avenir>
        <Spacer xs={14} />
        <Dash />
        <Spacer xs={isSm ? 30 : 60} />
      </div>
      <div id="checklist-container">
        <Avenir weight="500" fontSize={isSm ? 14 : 20} color="white">
          GENERAL CLAIM CHECKLIST – For Guidance Only
        </Avenir>
        {/* <Avenir weight="500" fontSize={isSm ? 14 : 20} color="white">
          The checklist explains the requirements for a claims procedure:
        </Avenir> */}
        <Spacer xs={isSm ? 20 : 30} />
        {list.map((el, idx) => (
          <div className="checklist-item" key={el + idx}>
            <Avenir fontSize={18} color="white" weight="500">
              {idx + 1}.
            </Avenir>
            <Spacer xs={5} horizontal />
            <Avenir fontSize={16} color="white">
              {el}
            </Avenir>
          </div>
        ))}
        <Spacer xs={20} />
        <Avenir fontSize={16} color="white">
          <i>
            * Please note that not every document will be relevant or available
            depending on the nature of the claim
          </i>
        </Avenir>
        <Spacer xs={4} />
        <Avenir fontSize={16} color="white">
          <i>** Please note list may not be exhaustive</i>
        </Avenir>
        <Spacer xs={4} />
        <Avenir fontSize={16} color="white">
          <i>***Final RFI to be developed by loss adjuster</i>
        </Avenir>
      </div>

      <Spacer xs={75} />
    </Div>
  );
};

export default ChecklistScreen;
