import { InputAdornment, TextField } from "@mui/material";
import styled from "styled-components";
import { ThemeColors } from "util/theme";

export const TextArea = styled(TextField).attrs((p) => {
  return {
    ...p,
    fullWidth: true,
    multiline: true,
    minRows: 3,
  };
})`
  outline: none;
  background-color: white;

  * {
    border: none !important;
  }

  input,
  textarea {
    /* padding: 10px 20px; */
    font-size: 14px !important;
    color: #666666;
  }
  & input::placeholder,
  textarea::placeholder {
    opacity: 1;
    font-size: 14px !important;
  }
`;
