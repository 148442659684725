import { Avenir } from "components/Avenir";
import Dash from "components/Dash";
import { Button } from "components/form";
import Spacer from "components/Spacer";
import useBreakpoint from "hooks/useBreakpoint";
import { useEffect } from "react";
import { useStateSelector } from "state";
import useActionsApp from "state/actionHooks/useActionsApp";
import { SubmitStatus } from "state/reducers/app";
import styled from "styled-components";

const Div = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  #container {
    flex: 1;
    margin: 0 30px;
    max-width: 506px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    ${({ theme }) => theme.breakpoints.down("sm")} {
      img {
        transform: scale(0.7);
      }
    }
  }
`;

const FinalScreen = () => {
  const { moveToIdx, submitStatus, idx } = useStateSelector(({ app }) => {
    return {
      moveToIdx: app.moveToIdx,
      submitStatus: app.submitStatus,
      idx: app.idx,
    };
  });

  const isSm = useBreakpoint("sm");

  const {
    setAdjusters,
    setSubmitStatus,
    setHideSlideTwo,
    setTempEnableReturnToForm,
  } = useActionsApp();

  useEffect(() => {
    if (idx === 5) {
      const timer = setTimeout(() => {
        setTempEnableReturnToForm(false);
        clearTimeout(timer);
      }, 300);
    }
  }, [setTempEnableReturnToForm, idx]);

  return (
    <Div>
      <div id="container">
        <img
          src={`images/submit-${
            submitStatus === SubmitStatus.Error ? "error" : "success"
          }.svg`}
          alt=""
        />
        <Spacer xs={8} md={25} />

        <Avenir fontSize={isSm ? 28 : 34} color={"white"} textAlign="center">
          {submitStatus === SubmitStatus.Success
            ? "We have received your claim"
            : "Error"}
        </Avenir>
        <Spacer xs={isSm ? 4 : 15} />
        <Dash />
        <Spacer xs={25} />
        <Avenir
          fontSize={isSm ? 14 : 16}
          color={"white"}
          textAlign="center"
          letterSpacing={0.5}
        >
          {submitStatus === SubmitStatus.Success
            ? `The appointed Loss Adjuster will be in contact shortly`
            : `An error ocurred, please try again`}
        </Avenir>
        <Spacer xs={50} />
        <Button
          fullWidth
          onClick={() => {
            setSubmitStatus(undefined);
            setAdjusters(undefined);
            setHideSlideTwo(true);
            setTempEnableReturnToForm(true);
            const timer = setTimeout(() => {
              setHideSlideTwo(false);
              clearTimeout(timer);
            }, 300);
            const timer2 = setTimeout(() => {
              moveToIdx(1);
              clearTimeout(timer2);
            }, 1);
          }}
        >
          {submitStatus === SubmitStatus.Success ? "OK" : "TRY AGAIN"}
        </Button>
      </div>
    </Div>
  );
};

export default FinalScreen;
