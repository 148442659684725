import { Avenir } from "components/Avenir";
import Dash from "components/Dash";
import { LogoMobile } from "components/Logo";
import { NavSmall } from "components/Nav";
import Spacer from "components/Spacer";
import useBreakpoint from "hooks/useBreakpoint";
import React from "react";
import { useStateSelector } from "state";
import styled from "styled-components";

const Div = styled.div`
  height: 100vh;
  overflow-x: auto;
  display: flex;
  flex-direction: column;

  ::-webkit-scrollbar {
    display: none;
  }

  #top {
    display: flex;
    flex-direction: column;
    align-items: center;

    #umr-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      #umr {
        position: absolute;
      }
      .left {
        left: 35px;
      }
    }
  }

  #contents {
    /* border: 1px solid white; */
    flex: 1;

    #form {
      /* border: 1px solid white; */
      margin: 0 auto;
      padding: 0 35px;
      max-width: 678px;
      display: flex;
      flex-direction: column;
      height: 100%;

      #flex {
        flex: 1;
      }
    }
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    #icon {
      height: 30px;
    }
  }
`;

interface IFormWrapperProps {
  children: React.ReactNode;
  isConfirmScreen?: boolean;
}

const FormWrapper: React.FC<IFormWrapperProps> = ({
  children,
  isConfirmScreen,
}) => {
  const { adjusters, hideSlideTwo } = useStateSelector(({ app }) => {
    return { adjusters: app.adjusters, hideSlideTwo: app.hideSlideTwo };
  });

  const isSm = useBreakpoint("sm");

  if (hideSlideTwo) return null;

  return (
    <Div>
      <div id="top">
        <NavSmall left />
        <Spacer xs={50} />
        <LogoMobile />
        <Spacer xs={isSm ? 30 : 0} />
        <img id="icon" src="images/submit-claim.svg" alt="" />
        <Spacer xs={isSm ? 10 : 25} />
        <Avenir color={"white"} fontSize={isSm ? 28 : 34}>
          Submit a claim
        </Avenir>
        <Spacer xs={4} md={14} />
        <Dash />
        <Spacer xs={isSm ? 30 : 50} />
        {isConfirmScreen && (
          <div id="umr-wrapper">
            <Avenir
              color={"white"}
              id="umr"
              fontSize={isSm ? 14 : 16}
              className={isSm ? "left" : ""}
            >
              Confirm your details
            </Avenir>
          </div>
        )}
        {!isConfirmScreen && adjusters && (
          <div id="umr-wrapper">
            <Avenir
              fontSize={isSm ? 14 : 16}
              color="rgba(255,255,255,0.7)"
              id="umr"
              noWrap
              className={isSm ? "left" : ""}
            >
              UMR: {adjusters.umr}
            </Avenir>
          </div>
        )}

        <Spacer xs={30} />
      </div>

      <div id="contents">
        <div id="form">
          {children}
          <Spacer xs={75} />
        </div>
      </div>
    </Div>
  );
};

export default FormWrapper;
