import useBreakpoint from "hooks/useBreakpoint";
import styled from "styled-components";

const Div = styled.div<{ isHome?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  img {
    /* position: absolute; */
    transform: translateY(${({ isHome }) => (isHome ? 90 : 0)}px);
  }
`;

interface ILogoMobileProps {
  isHome?: boolean;
}

export const LogoMobile: React.FC<ILogoMobileProps> = ({ isHome }) => {
  const isSm = useBreakpoint("sm");
  if (!isSm) return null;
  return (
    <Div isHome={isHome}>
      <img src="images/brit-logo-white.svg" />
    </Div>
  );
};
