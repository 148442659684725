import SvgReturnArrowLeft from "assets/SvgReturnArrowLeft";
import SvgReturnArrowRight from "assets/SvgReturnArrowRight";
import { Avenir } from "components/Avenir";
import Spacer from "components/Spacer";
import useBreakpoint from "hooks/useBreakpoint";
import { useStateSelector } from "state";
import styled from "styled-components";

const Div = styled.div`
  position: absolute;
  top: 95px;
  width: 100vw;
  z-index: 1;

  #nav-contents {
    position: relative;
    margin: auto;
    max-width: 1400px;
    display: flex;
    #left {
      position: absolute;
      left: 20px;
    }
    #right {
      position: absolute;
      right: 20px;
    }
  }
`;

const ReturnDiv = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  flex: 0;
`;

interface INavProps {}

const Nav: React.FC<INavProps> = () => {
  const { idx, next, prev } = useStateSelector(({ app }) => {
    return { idx: app.idx, next: app.next, prev: app.prev };
  });

  const isLg = useBreakpoint("lg");
  const isSm = useBreakpoint("sm");

  if (idx === 5 || isSm) return null;

  return (
    <Div>
      <div id="nav-contents">
        {idx === 0 && (
          <div id="right">
            <ReturnDiv onClick={() => next()}>
              <Avenir fontSize={isLg ? 18 : 24} color="white" weight="400">
                Return
              </Avenir>
              <Spacer xs={17} horizontal />
              <SvgReturnArrowRight />
            </ReturnDiv>
          </div>
        )}
        {idx > 1 && (
          <div id="left">
            <ReturnDiv onClick={() => prev()}>
              <SvgReturnArrowLeft />
              <Spacer xs={17} horizontal />
              <Avenir fontSize={isLg ? 18 : 24} color="white" weight="400">
                Return
              </Avenir>
            </ReturnDiv>
          </div>
        )}
      </div>
    </Div>
  );
};

export default Nav;
