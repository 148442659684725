import { useMediaQuery, useTheme } from "@mui/material";

const useBreakpoint = (size: "xs" | "sm" | "md" | "lg" | "xl") => {
  const theme = useTheme();
  const breakpoint = useMediaQuery(theme.breakpoints.down(size));

  return breakpoint;
};

export default useBreakpoint;
