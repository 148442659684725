import styled, { css } from "styled-components";
import { ThemeColors } from "util/theme";

const Div = styled.div<{ error?: boolean }>`
  font-size: 14px;
  margin-top: 8px;
  width: 100%;

  display: flex;

  color: ${ThemeColors.BLUE};

  ${({ error }) =>
    error &&
    css`
      justify-content: flex-end;
      color: ${ThemeColors.ERROR};
      span {
        text-align: end;
      }
    `};

  ${({ theme }) => theme.breakpoints.down("sm")} {
    font-size: 12px;
  }
`;

interface IHelperTextProps {
  enabled: boolean;
  text: string;
  error?: boolean;
}

export const HelperText: React.FC<IHelperTextProps> = ({
  enabled,
  text,
  error,
}) => {
  if (!enabled) return null;
  return (
    <Div error={error}>
      <span>{text}</span>
    </Div>
  );
};
