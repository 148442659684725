import styled, { css } from "styled-components";
import { Typography } from "@mui/material";
import { ThemeColors } from "util/theme";

interface FontProps {
  weight?: "normal" | "bold" | "300" | "400" | "500" | "600";
  themeColor?: ThemeColors;
}

export const Avenir = styled(Typography)<FontProps>`
  font-family: "Avenir", sans-serif !important;
  margin: 0 !important;

  ${({ themeColor }) =>
    themeColor &&
    css`
      color: ${themeColor} !important;
    `};

  ${({ weight }) =>
    weight &&
    css`
      font-weight: ${weight} !important;
    `};
`;
