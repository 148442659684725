import { InputAdornment, TextField } from "@mui/material";
import styled, { css } from "styled-components";

export const Input = styled(TextField).attrs((p) => {
  return { ...p, variant: "standard", fullWidth: true };
})<{ placeholderGray?: boolean }>`
  outline: none;

  .MuiInputBase-root {
    display: flex;
    padding: 0 0;
    border-radius: 0px;
    /* border: 1px solid green; */
  }
  input {
  }

  input,
  textarea {
    font-size: 16px;
    color: white;
    margin: 0 !important;
    /* border: 1px solid green; */
    align-self: flex-start !important;
  }
  textarea {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
  }
  & input::placeholder,
  textarea::placeholder {
    opacity: 1;
    font-size: 16px;
    font-weight: 500 !important;

    ${({ placeholderGray }) =>
      placeholderGray &&
      css`
        opacity: 0.6;
      `};
  }

  & .MuiInput-underline:hover:before {
    border-bottom-color: white !important;
  }

  & .MuiInput-underline:before {
    border-bottom-color: white;
  }
  & .MuiInput-underline:after {
    border-bottom-color: white;
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    input {
      font-size: 14px;
    }
    textarea {
      font-size: 14px;
    }

    & input::placeholder,
    textarea::placeholder {
      font-size: 14px;
    }
  }
`;

export const EndText = styled.span`
  color: white;
  opacity: 0.4;
  font-size: 16px;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    font-size: 14px !important;
  }
`;
export const StartText = styled.span`
  color: white;
  margin-right: 3px;
  font-weight: 500 !important;
  font-size: 16px;
  /* border: 1px solid white; */
  margin-top: 1px;
  width: 100%;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    * {
      font-size: 14px !important;
    }
  }
`;

export const StyledInputAdornment = styled(InputAdornment)`
  margin-right: 0 !important;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    * {
      font-size: 14px !important;
    }
  }
`;

export const StyledInputAdornmentMulti = styled(StyledInputAdornment)`
  height: 40px !important;
  align-self: flex-start !important;
  margin-bottom: 2px;
`;
