import styled, { css } from "styled-components";
import { Button as MuiButton } from "@mui/material";
import { ThemeColors } from "util/theme";

export const ButtonSmall = styled(MuiButton).attrs({
  variant: "contained",
})`
  text-transform: none !important;
  font-size: 12px !important;
  letter-spacing: 2px;
  min-height: 28px;
  border-radius: 0 !important;
  box-shadow: none;
  color: white !important;
  font-weight: 400 !important;

  &:hover {
    box-shadow: none;
    background-color: ${ThemeColors.BLUE} !important;
  }

  &.Mui-disabled {
    background-color: ${ThemeColors.BLUE} !important;
    color: rgba(255, 255, 255, 0.3) !important;
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    font-size: 10px !important;
    min-height: 23px;
  }
`;
