import styled from "styled-components";
import { ThemeColors } from "util/theme";

const Dash = styled.div<{ isWhite?: boolean }>`
  height: 7px;
  width: 70px;
  background-color: ${({ isWhite }) => (isWhite ? "white" : ThemeColors.BLUE)};
`;

export default Dash;
