import React from "react";
import styled from "styled-components";

const Container = styled.div`
  min-height: 100vh;
`;

interface ISlideContainerProps {
  children: React.ReactNode;
}

export const SlideContainer: React.FC<ISlideContainerProps> = ({
  children,
}) => {
  return <Container className="keen-slider__slide">{children}</Container>;
};
