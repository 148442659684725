import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAdjuster, IAjusters, IImage } from "api/types";

export enum SubmitStatus {
  Success,
  Error,
}

interface State {
  idx: number;
  next: () => void;
  prev: () => void;
  moveToIdx: (a: number) => void;
  adjusters: IAjusters | undefined;
  submitStatus: SubmitStatus | undefined;
  hideSlideTwo: boolean;
  tempEnableReturnToForm: boolean;
  canProceed: boolean;
  formData: {
    adjuster: IAdjuster;
    insuredName: string;
    contactDetails: string;
    dateOfLoss: string;
    locationOfLoss: string;
    lossDetails: string;
  };
  images: IImage[];
}

const initialState: State = {
  idx: 1,
  next: () => {},
  prev: () => {},
  moveToIdx: () => {},
  adjusters: undefined,
  submitStatus: undefined,
  hideSlideTwo: false,
  tempEnableReturnToForm: true,
  canProceed: false,
  formData: {
    adjuster: { name: "", emailAddress: "", telephone: "" },
    insuredName: "",
    contactDetails: "",
    dateOfLoss: "",
    locationOfLoss: "",
    lossDetails: "",
  },
  images: [],
};

export const slice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setIdx: (state: State, action: PayloadAction<number>) => {
      state.idx = action.payload;
    },
    setNavigation: (
      state: State,
      action: PayloadAction<{
        next: () => void;
        prev: () => void;
        moveToIdx: (a: number) => void;
      }>
    ) => {
      state.next = action.payload.next;
      state.prev = action.payload.prev;
      state.moveToIdx = action.payload.moveToIdx;
    },
    setAdjusters: (
      state: State,
      action: PayloadAction<IAjusters | undefined>
    ) => {
      state.adjusters = action.payload;
    },
    setSubmitStatus: (
      state: State,
      action: PayloadAction<SubmitStatus | undefined>
    ) => {
      state.submitStatus = action.payload;
    },
    setHideSlideTwo: (state: State, action: PayloadAction<boolean>) => {
      state.hideSlideTwo = action.payload;
    },
    setTempEnableReturnToForm: (
      state: State,
      action: PayloadAction<boolean>
    ) => {
      state.tempEnableReturnToForm = action.payload;
    },
    setCanProceed: (state: State, action: PayloadAction<boolean>) => {
      state.canProceed = action.payload;
    },
    setFormData: (state: State, action: PayloadAction<any>) => {
      state.formData = action.payload;
    },
    setImages: (state: State, action: PayloadAction<IImage[]>) => {
      console.log("payload", action.payload);
      state.images = action.payload;
    },
  },
});

export default slice.reducer;
