import styled, { css } from "styled-components";
import Select from "react-select";
import { ThemeColors } from "util/theme";

interface StyledSelectProps {
  error?: boolean;
}

export const StyledSelect = styled(Select).attrs({
  classNamePrefix: "select",
})<StyledSelectProps>`
  text-align: left;

  * {
    transition: none;
  }

  .select__control {
    padding: 0 !important;
    background-color: rgba(0, 0, 0, 0);
    width: 100%;
    cursor: pointer;
    box-shadow: none;
    border-radius: 0px;
    border: none;
    border-bottom: 1px solid white;
    display: flex;
    flex-wrap: nowrap;
    &--menu-is-open {
      /* border-bottom: none !important; */
    }
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    height: 35px !important;
  }
  .select__control:hover {
    border-bottom: 1px solid white;
  }

  &:hover {
    .select__control:after {
      content: "";
      position: absolute;
      width: 100%;
      bottom: 0;
      border-bottom: 1px solid white;
    }
  }

  .select__value-container {
    padding-left: 0;
    display: flex;
    flex-wrap: nowrap;

    &--has-value {
      :before {
        content: "Adjuster: ";
        margin-right: 3px;
        color: white;
        font-weight: 500 !important;

        ${({ theme }) => theme.breakpoints.down("sm")} {
          font-size: 14px !important;
        }
      }
    }
  }

  .select__single-value {
    color: white;
  }

  .select__placeholder {
    color: white;
    font-weight: 500;
  }

  .select__indicator {
    color: ${ThemeColors.BLUE} !important;
  }
  .select__indicator-separator {
    display: none;
  }

  .select__menu {
    margin-top: 0px !important;
    padding: 0px !important;
    border-radius: 0px;
    border-top: none;
  }

  .select__option {
    margin: 0 !important;
    padding-left: 25px;
    border-bottom: 1px solid rgba(102, 102, 102, 0.15);
    letter-spacing: 0.5px;
    &--is-focused {
      background-color: white;
    }
    &--is-selected {
      background-color: ${({ theme }) => theme.palette.primary.main};
    }
  }

  ${({ error }) =>
    error &&
    css`
      .select__control {
        border-bottom: 2px solid ${ThemeColors.ERROR};
      }
      .select__control:hover {
        border-bottom: 2px solid ${ThemeColors.ERROR};
      }

      .select__control:after {
        display: none;
      }
    `};

  ${({ theme }) => theme.breakpoints.down("sm")} {
    .select__control {
      font-size: 14px !important;
    }
  }
`;

export const DropDownIndicator = styled.div`
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
`;
