const SvgReturnArrowRight = () => {
  return (
    <svg
      width="41"
      height="21"
      viewBox="0 0 41 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.000244141 10.8233C0.000244141 10.0925 0.592711 9.5 1.32355 9.5H38.8547C39.5855 9.5 40.178 10.0925 40.178 10.8233C40.178 11.5542 39.5855 12.1466 38.8547 12.1466H1.32355C0.592711 12.1466 0.000244141 11.5542 0.000244141 10.8233Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.9382 0.887588C30.455 0.370803 31.2929 0.370804 31.8096 0.88759L40.4957 9.57366C40.7439 9.82183 40.8833 10.1584 40.8833 10.5094C40.8833 10.8604 40.7439 11.1969 40.4957 11.4451L31.8096 20.1311C31.2928 20.6479 30.4549 20.6479 29.9381 20.1311C29.4213 19.6143 29.4213 18.7765 29.9381 18.2597L37.6885 10.5094L29.9382 2.75903C29.4214 2.24225 29.4214 1.40437 29.9382 0.887588Z"
        fill="white"
      />
    </svg>
  );
};

export default SvgReturnArrowRight;
