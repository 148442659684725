const SvgReturnArrowLeft = () => {
  return (
    <svg
      width="41"
      height="21"
      viewBox="0 0 41 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.8828 10.8233C40.8828 10.0925 40.2903 9.5 39.5595 9.5H2.02837C1.29753 9.5 0.705063 10.0925 0.705063 10.8233C0.705063 11.5542 1.29753 12.1466 2.02837 12.1466H39.5595C40.2903 12.1466 40.8828 11.5542 40.8828 10.8233Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9449 0.887588C10.4281 0.370803 9.5902 0.370804 9.07342 0.88759L0.38736 9.57366C0.13919 9.82183 -0.000229836 10.1584 -0.000228882 10.5094C-0.000226974 10.8604 0.139194 11.1969 0.387365 11.4451L9.07349 20.1311C9.59028 20.6479 10.4282 20.6479 10.9449 20.1311C11.4617 19.6143 11.4617 18.7765 10.9449 18.2597L3.19453 10.5094L10.9449 2.75903C11.4616 2.24225 11.4616 1.40437 10.9449 0.887588Z"
        fill="white"
      />
    </svg>
  );
};

export default SvgReturnArrowLeft;
