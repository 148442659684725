import { useState } from "react";
import { Button, HelperText, Input } from "components/form";
import Spacer from "components/Spacer";
import styled from "styled-components";
import * as Yup from "yup";
import { useFormik } from "formik";
import { getAdjusters } from "api/client";
import useActionsApp from "state/actionHooks/useActionsApp";
import useBreakpoint from "hooks/useBreakpoint";
import { Avenir } from "components/Avenir";
import { useStateSelector } from "state";

const Div = styled.form`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const validationSchema = Yup.object({
  umr: Yup.string().required(),
});

export const UMRForm = () => {
  const next = useStateSelector(({ app }) => app.next);
  const [err, setErr] = useState("");
  const { setAdjusters } = useActionsApp();
  const isSm = useBreakpoint("sm");

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    isValid,
    isSubmitting,
  } = useFormik({
    initialValues: {
      umr: "",
    },
    validationSchema,
    onSubmit: (values) => {
      setErr("");
      return getAdjusters(values.umr)
        .then((res) => {
          setAdjusters(res);
          setTimeout(() => {
            next();
          }, 1);
        })
        .catch((err) => {
          console.log("err:", err);
          setAdjusters(undefined);
          setErr(err.message);
        });
    },
    // isInitialValid: false,
  });

  return (
    <Div>
      {isSm && (
        <>
          <Avenir color={"white"}>Unique Market Reference*</Avenir>
          <Spacer xs={10} />
        </>
      )}
      <Input
        placeholder={isSm ? "" : "Unique Market Reference*"}
        onKeyDown={(e) => {
          if (e.keyCode === 9 || e.keyCode === 13) {
            setAdjusters(undefined);
            e.preventDefault();
            handleSubmit();
          }
        }}
        InputProps={{
          spellCheck: false,
        }}
        id="umr"
        name="umr"
        type="text"
        onChange={(e) => {
          handleChange(e);
          setErr("");
        }}
        onBlur={handleBlur}
        value={values.umr}
      />
      <HelperText
        enabled={!isSubmitting}
        text={err ? err : "Enter the Unique Market Reference to proceed"}
        error={Boolean(err)}
      />
      <div id="flex" />
      <Spacer xs={20} />
      <Button
        fullWidth
        type="submit"
        onClick={(e) => {
          e.preventDefault();
          setAdjusters(undefined);
          handleSubmit();
        }}
        disabled={!isValid || isSubmitting || values.umr.length < 14}
      >
        PROCEED
      </Button>
    </Div>
  );
};
